/* Ion.RangeSlider, Modern Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
    background: url(../img/sprite-skin-modern.png) repeat-x;
}

.irs {
    height: 50px;
}
.irs-with-grid {
    height: 70px;
}
.irs-line {
    height: 6px; top: 25px;
}
    .irs-line-left {
        height: 6px;
        background-position: 0 -30px;
    }
    .irs-line-mid {
        height: 6px;
        background-position: 0 0;
    }
    .irs-line-right {
        height: 6px;
        background-position: 100% -30px;
    }

.irs-bar {
    height: 6px; top: 25px;
    background-position: 0 -60px;
}
    .irs-bar-edge {
        top: 25px;
        height: 6px; width: 6px;
        background-position: 0 -90px;
    }

.irs-shadow {
    height: 5px; top: 25px;
    background: #000;
    opacity: 0.25;
}
.lt-ie9 .irs-shadow {
    filter: alpha(opacity=25);
}

.irs-slider {
    width: 11px; height: 18px;
    top: 31px;
    background-position: 0 -120px;
}
.irs-slider.state_hover, .irs-slider:hover {
    background-position: 0 -150px;
}

.irs-min, .irs-max {
    color: #999;
    font-size: 10px; line-height: 1.333;
    text-shadow: none;
    top: 0; padding: 1px 3px;
    background: #e1e4e9;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.irs-from, .irs-to, .irs-single {
    color: #fff;
    font-size: 10px; line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: #02c2cb;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.irs-from:after, .irs-to:after, .irs-single:after {
    position: absolute; display: block; content: "";
    bottom: -6px; left: 50%;
    width: 0; height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #02c2cb;
}

.irs-grid {
    height: 34px;
}
.irs-grid-pol {
    background: #c0c0c0;
}
.irs-grid-text {
    bottom: 12px;
    color: #c0c0c0;
}

.irs-disable-mask {

}
.irs-disabled {

}
.lt-ie9 .irs-disabled {

}
