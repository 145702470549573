.gsm-animation-repeat {
    animation: shandong 1s infinite;
    color: #ef6e6e;
}


@keyframes shandong {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}